<template>
    <div class="row">
        <div class="col">
            <h4>Error page</h4>
        </div>
    </div>
</template>

<script>
    export default {
        name: "error-page",
    }
</script>

<style scoped>

</style>